import Fassaden1 from "../Portfolio/Fassaden/Fassaden (1).jpg";
import Fassaden2 from "../Portfolio/Fassaden/Fassaden (2).jpg";
import Fassaden3 from "../Portfolio/Fassaden/Fassaden (3).jpg";
import Fassaden4 from "../Portfolio/Fassaden/Fassaden (4).jpg";
import Fassaden5 from "../Portfolio/Fassaden/Fassaden (5).jpg";
import Fassaden6 from "../Portfolio/Fassaden/Fassaden (6).jpg";
import Fassaden7 from "../Portfolio/Fassaden/Fassaden (7).jpg";
import Fassaden8 from "../Portfolio/Fassaden/Fassaden (8).jpg";
import Fassaden9 from "../Portfolio/Fassaden/Fassaden (9).jpg";
import Fassaden10 from "../Portfolio/Fassaden/Fassaden (10).jpg";
import Fassaden11 from "../Portfolio/Fassaden/Fassaden (11).jpg";
import Fassaden12 from "../Portfolio/Fassaden/Fassaden (12).jpg";
import Fassaden13 from "../Portfolio/Fassaden/Fassaden (13).jpg";
import Fassaden14 from "../Portfolio/Fassaden/Fassaden (14).jpg";
import Fassaden15 from "../Portfolio/Fassaden/Fassaden (15).jpg";
import Fassaden16 from "../Portfolio/Fassaden/Fassaden (16).jpg";
import Fassaden17 from "../Portfolio/Fassaden/Fassaden (17).jpg";
import Fassaden18 from "../Portfolio/Fassaden/Fassaden (18).jpg";
import Fassaden19 from "../Portfolio/Fassaden/Fassaden (19).jpg";
import Fassaden20 from "../Portfolio/Fassaden/Fassaden (20).jpg";
import Fassaden21 from "../Portfolio/Fassaden/Fassaden (21).jpg";
import Fassaden22 from "../Portfolio/Fassaden/Fassaden (22).jpg";
import Fassaden23 from "../Portfolio/Fassaden/Fassaden (23).jpg";
import Fassaden24 from "../Portfolio/Fassaden/Fassaden (24).jpg";
import Fassaden25 from "../Portfolio/Fassaden/Fassaden (25).jpg";
import Fassaden26 from "../Portfolio/Fassaden/Fassaden (26).jpg";
import Fassaden27 from "../Portfolio/Fassaden/Fassaden (27).jpg";
import Fassaden28 from "../Portfolio/Fassaden/Fassaden (28).jpg";
import Fassaden29 from "../Portfolio/Fassaden/Fassaden (29).jpg";
import Fassaden30 from "../Portfolio/Fassaden/Fassaden (30).jpg";
import Fassaden31 from "../Portfolio/Fassaden/Fassaden (31).jpg";
import Fassaden32 from "../Portfolio/Fassaden/Fassaden (32).jpg";
import Fassaden33 from "../Portfolio/Fassaden/Fassaden (33).jpg";
import Fassaden34 from "../Portfolio/Fassaden/Fassaden (34).jpg";
import Fassaden35 from "../Portfolio/Fassaden/Fassaden (35).jpg";
import Fassaden36 from "../Portfolio/Fassaden/Fassaden (36).jpg";
import Fassaden37 from "../Portfolio/Fassaden/Fassaden (37).jpg";
import Fassaden38 from "../Portfolio/Fassaden/Fassaden (38).jpg";
import Fassaden39 from "../Portfolio/Fassaden/Fassaden (39).jpg";
import Fassaden40 from "../Portfolio/Fassaden/Fassaden (40).jpg";
import Fassaden41 from "../Portfolio/Fassaden/Fassaden (41).jpg";
import Fassaden42 from "../Portfolio/Fassaden/Fassaden (42).jpg";
import Fassaden43 from "../Portfolio/Fassaden/Fassaden (43).jpg";
import Fassaden44 from "../Portfolio/Fassaden/Fassaden (44).jpg";
import Fassaden45 from "../Portfolio/Fassaden/Fassaden (45).jpg";
import Fassaden46 from "../Portfolio/Fassaden/Fassaden (46).jpg";
import Fassaden47 from "../Portfolio/Fassaden/Fassaden (47).jpg";
import Fassaden48 from "../Portfolio/Fassaden/Fassaden (48).jpg";
import Fassaden49 from "../Portfolio/Fassaden/Fassaden (49).jpg";
import Fassaden50 from "../Portfolio/Fassaden/Fassaden (50).jpg";
import Fassaden51 from "../Portfolio/Fassaden/Fassaden (51).jpg";
import Fassaden52 from "../Portfolio/Fassaden/Fassaden (52).jpg";
import Fassaden53 from "../Portfolio/Fassaden/Fassaden (53).jpg";
import Fassaden54 from "../Portfolio/Fassaden/Fassaden (54).jpg";
import Fassaden55 from "../Portfolio/Fassaden/Fassaden (55).jpg";
import Fassaden56 from "../Portfolio/Fassaden/Fassaden (56).jpg";
import Fassaden57 from "../Portfolio/Fassaden/Fassaden (57).jpg";
import Fassaden58 from "../Portfolio/Fassaden/Fassaden (58).jpg";
import Fassaden59 from "../Portfolio/Fassaden/Fassaden (59).jpg";
import Fassaden60 from "../Portfolio/Fassaden/Fassaden (60).jpg";
import Fassaden61 from "../Portfolio/Fassaden/Fassaden (61).jpg";
import Fassaden62 from "../Portfolio/Fassaden/Fassaden (62).jpg";
import Fassaden63 from "../Portfolio/Fassaden/Fassaden (63).jpg";
import Fassaden64 from "../Portfolio/Fassaden/Fassaden (64).jpg";
import Fassaden65 from "../Portfolio/Fassaden/Fassaden (65).jpg";
import Fassaden66 from "../Portfolio/Fassaden/Fassaden (66).jpg";
import Fassaden67 from "../Portfolio/Fassaden/Fassaden (67).jpg";
import Fassaden68 from "../Portfolio/Fassaden/Fassaden (68).jpg";
import Fassaden69 from "../Portfolio/Fassaden/Fassaden (69).jpg";
import Fassaden70 from "../Portfolio/Fassaden/Fassaden (70).jpg";
import Fassaden71 from "../Portfolio/Fassaden/Fassaden (71).jpg";
import Fassaden72 from "../Portfolio/Fassaden/Fassaden (72).jpg";
import Fassaden73 from "../Portfolio/Fassaden/Fassaden (73).jpg";
import Fassaden74 from "../Portfolio/Fassaden/Fassaden (74).jpg";
import Fassaden75 from "../Portfolio/Fassaden/Fassaden (75).jpg";
import Fassaden76 from "../Portfolio/Fassaden/Fassaden (76).jpg";
import Fassaden77 from "../Portfolio/Fassaden/Fassaden (77).jpg";
import Fassaden78 from "../Portfolio/Fassaden/Fassaden (78).jpg";
import Fassaden79 from "../Portfolio/Fassaden/Fassaden (79).jpg";
import Fassaden80 from "../Portfolio/Fassaden/Fassaden (80).jpg";
import Fassaden81 from "../Portfolio/Fassaden/Fassaden (81).jpg";
import Fassaden82 from "../Portfolio/Fassaden/Fassaden (82).jpg";
import Fassaden83 from "../Portfolio/Fassaden/Fassaden (83).jpg";
import Fassaden84 from "../Portfolio/Fassaden/Fassaden (84).jpg";
import Fassaden85 from "../Portfolio/Fassaden/Fassaden (85).jpg";
import Fassaden86 from "../Portfolio/Fassaden/Fassaden (86).jpg";
import Fassaden87 from "../Portfolio/Fassaden/Fassaden (87).jpg";
import Fassaden88 from "../Portfolio/Fassaden/Fassaden (88).jpg";
import Fassaden89 from "../Portfolio/Fassaden/Fassaden (89).jpg";
import Fassaden90 from "../Portfolio/Fassaden/Fassaden (90).jpg";
import Fassaden91 from "../Portfolio/Fassaden/Fassaden (91).jpg";
import Fassaden92 from "../Portfolio/Fassaden/Fassaden (92).jpg";
import Fassaden93 from "../Portfolio/Fassaden/Fassaden (93).jpg";
import Fassaden94 from "../Portfolio/Fassaden/Fassaden (94).jpg";
import Fassaden95 from "../Portfolio/Fassaden/Fassaden (95).jpg";
import Fassaden96 from "../Portfolio/Fassaden/Fassaden (96).jpg";
import Fassaden97 from "../Portfolio/Fassaden/Fassaden (97).jpg";
import Fassaden98 from "../Portfolio/Fassaden/Fassaden (98).jpg";
import Fassaden99 from "../Portfolio/Fassaden/Fassaden (99).jpg";
import Fassaden100 from "../Portfolio/Fassaden/Fassaden (100).jpg";
import Fassaden101 from "../Portfolio/Fassaden/Fassaden (101).jpg";
import Fassaden102 from "../Portfolio/Fassaden/Fassaden (102).jpg";
import Fassaden103 from "../Portfolio/Fassaden/Fassaden (103).jpg";
import Fassaden104 from "../Portfolio/Fassaden/Fassaden (104).jpg";
import Fassaden105 from "../Portfolio/Fassaden/Fassaden (105).jpg";
import Fassaden106 from "../Portfolio/Fassaden/Fassaden (106).jpg";
import Fassaden107 from "../Portfolio/Fassaden/Fassaden (107).jpg";
import Fassaden108 from "../Portfolio/Fassaden/Fassaden (108).jpg";
import Boden1 from "../Portfolio/Bodenleger/Boden (1).jpg";
import Boden2 from "../Portfolio/Bodenleger/Boden (2).jpg";
import Boden3 from "../Portfolio/Bodenleger/Boden (3).jpg";
import Boden4 from "../Portfolio/Bodenleger/Boden (4).jpg";
import Boden5 from "../Portfolio/Bodenleger/Boden (5).jpg";
import Boden6 from "../Portfolio/Bodenleger/Boden (6).jpg";
import Boden7 from "../Portfolio/Bodenleger/Boden (7).jpg";
import Boden8 from "../Portfolio/Bodenleger/Boden (8).jpg";
import Boden9 from "../Portfolio/Bodenleger/Boden (9).jpg";
import Boden10 from "../Portfolio/Bodenleger/Boden (10).jpg";
import Boden11 from "../Portfolio/Bodenleger/Boden (11).jpg";
import Boden12 from "../Portfolio/Bodenleger/Boden (12).jpg";
import Boden13 from "../Portfolio/Bodenleger/Boden (13).jpg";
import Boden14 from "../Portfolio/Bodenleger/Boden (14).jpg";
import Boden15 from "../Portfolio/Bodenleger/Boden (15).jpg";
import Boden16 from "../Portfolio/Bodenleger/Boden (16).jpg";
import Boden17 from "../Portfolio/Bodenleger/Boden (17).jpg";
import Boden18 from "../Portfolio/Bodenleger/Boden (18).jpg";
import Boden19 from "../Portfolio/Bodenleger/Boden (19).jpg";
import Boden20 from "../Portfolio/Bodenleger/Boden (20).jpg";
import Boden21 from "../Portfolio/Bodenleger/Boden (21).jpg";
import Boden22 from "../Portfolio/Bodenleger/Boden (22).jpg";
import Boden23 from "../Portfolio/Bodenleger/Boden (23).jpg";
import Boden24 from "../Portfolio/Bodenleger/Boden (24).jpg";
import Boden25 from "../Portfolio/Bodenleger/Boden (25).jpg";
import Boden26 from "../Portfolio/Bodenleger/Boden (26).jpg";
import Boden27 from "../Portfolio/Bodenleger/Boden (27).jpg";
import Boden28 from "../Portfolio/Bodenleger/Boden (28).jpg";
import Boden29 from "../Portfolio/Bodenleger/Boden (29).jpg";
import Boden30 from "../Portfolio/Bodenleger/Boden (30).jpg";
import Boden31 from "../Portfolio/Bodenleger/Boden (31).jpg";
import Boden32 from "../Portfolio/Bodenleger/Boden (32).jpg";
import Boden33 from "../Portfolio/Bodenleger/Boden (33).jpg";
import Boden34 from "../Portfolio/Bodenleger/Boden (34).jpg";
import Boden35 from "../Portfolio/Bodenleger/Boden (35).jpg";
import Boden36 from "../Portfolio/Bodenleger/Boden (36).jpg";
import Boden37 from "../Portfolio/Bodenleger/Boden (37).jpg";
import Boden38 from "../Portfolio/Bodenleger/Boden (38).jpg";
import Boden39 from "../Portfolio/Bodenleger/Boden (39).jpg";
import Boden40 from "../Portfolio/Bodenleger/Boden (40).jpg";
import Boden41 from "../Portfolio/Bodenleger/Boden (41).jpg";
import Boden42 from "../Portfolio/Bodenleger/Boden (42).jpg";
import Boden43 from "../Portfolio/Bodenleger/Boden (43).jpg";
import Boden44 from "../Portfolio/Bodenleger/Boden (44).jpg";
import Boden45 from "../Portfolio/Bodenleger/Boden (45).jpg";
import Boden46 from "../Portfolio/Bodenleger/Boden (46).jpg";
import Boden47 from "../Portfolio/Bodenleger/Boden (47).jpg";
import Boden48 from "../Portfolio/Bodenleger/Boden (48).jpg";
import Boden49 from "../Portfolio/Bodenleger/Boden (49).jpg";
import Boden50 from "../Portfolio/Bodenleger/Boden (50).jpg";
import Boden51 from "../Portfolio/Bodenleger/Boden (51).jpg";
import Boden52 from "../Portfolio/Bodenleger/Boden (52).jpg";
import Boden53 from "../Portfolio/Bodenleger/Boden (53).jpg";
import Boden54 from "../Portfolio/Bodenleger/Boden (54).jpg";
import Boden55 from "../Portfolio/Bodenleger/Boden (55).jpg";
import Boden56 from "../Portfolio/Bodenleger/Boden (56).jpg";
import Boden57 from "../Portfolio/Bodenleger/Boden (57).jpg";
import Boden58 from "../Portfolio/Bodenleger/Boden (58).jpg";
import Boden59 from "../Portfolio/Bodenleger/Boden (59).jpg";
import Boden60 from "../Portfolio/Bodenleger/Boden (60).jpg";
import Boden61 from "../Portfolio/Bodenleger/Boden (61).jpg";
import Boden62 from "../Portfolio/Bodenleger/Boden (62).jpg";
import Boden63 from "../Portfolio/Bodenleger/Boden (63).jpg";
import Boden64 from "../Portfolio/Bodenleger/Boden (64).jpg";
import Boden65 from "../Portfolio/Bodenleger/Boden (65).jpg";
import Boden66 from "../Portfolio/Bodenleger/Boden (66).jpg";
import Boden67 from "../Portfolio/Bodenleger/Boden (67).jpg";
import Boden68 from "../Portfolio/Bodenleger/Boden (68).jpg";
import Boden69 from "../Portfolio/Bodenleger/Boden (69).jpg";
import Boden70 from "../Portfolio/Bodenleger/Boden (70).jpg";
import Boden71 from "../Portfolio/Bodenleger/Boden (71).jpg";
import Boden72 from "../Portfolio/Bodenleger/Boden (72).jpg";
import Boden73 from "../Portfolio/Bodenleger/Boden (73).jpg";
import Boden74 from "../Portfolio/Bodenleger/Boden (74).jpg";
import Boden75 from "../Portfolio/Bodenleger/Boden (75).jpg";
import Boden76 from "../Portfolio/Bodenleger/Boden (76).jpg";
import Boden77 from "../Portfolio/Bodenleger/Boden (77).jpg";
import Boden78 from "../Portfolio/Bodenleger/Boden (78).jpg";
import Boden79 from "../Portfolio/Bodenleger/Boden (79).jpg";
import Boden80 from "../Portfolio/Bodenleger/Boden (80).jpg";
import Boden81 from "../Portfolio/Bodenleger/Boden (81).jpg";
import Boden82 from "../Portfolio/Bodenleger/Boden (82).jpg";
import Boden83 from "../Portfolio/Bodenleger/Boden (83).jpg";
import Boden84 from "../Portfolio/Bodenleger/Boden (84).jpg";
import Boden85 from "../Portfolio/Bodenleger/Boden (85).jpg";
import Boden86 from "../Portfolio/Bodenleger/Boden (86).jpg";
import Boden87 from "../Portfolio/Bodenleger/Boden (87).jpg";
import Boden88 from "../Portfolio/Bodenleger/Boden (88).jpg";
import Boden89 from "../Portfolio/Bodenleger/Boden (89).jpg";
import Boden90 from "../Portfolio/Bodenleger/Boden (90).jpg";
import Boden91 from "../Portfolio/Bodenleger/Boden (91).jpg";
import Boden92 from "../Portfolio/Bodenleger/Boden (92).jpg";
import Boden93 from "../Portfolio/Bodenleger/Boden (93).jpg";
import Boden94 from "../Portfolio/Bodenleger/Boden (94).jpg";
import Boden95 from "../Portfolio/Bodenleger/Boden (95).jpg";
import Boden96 from "../Portfolio/Bodenleger/Boden (96).jpg";
import Boden97 from "../Portfolio/Bodenleger/Boden (97).jpg";
import Boden98 from "../Portfolio/Bodenleger/Boden (98).jpg";
import Boden99 from "../Portfolio/Bodenleger/Boden (99).jpg";
import Boden100 from "../Portfolio/Bodenleger/Boden (100).jpg";
import Boden101 from "../Portfolio/Bodenleger/Boden (101).jpg";
import Boden102 from "../Portfolio/Bodenleger/Boden (102).jpg";
import Boden103 from "../Portfolio/Bodenleger/Boden (103).jpg";
import Boden104 from "../Portfolio/Bodenleger/Boden (104).jpg";
import Boden105 from "../Portfolio/Bodenleger/Boden (105).jpg";
import Boden106 from "../Portfolio/Bodenleger/Boden (106).jpg";
import Boden107 from "../Portfolio/Bodenleger/Boden (107).jpg";
import Boden108 from "../Portfolio/Bodenleger/Boden (108).jpg";
import Boden109 from "../Portfolio/Bodenleger/Boden (109).jpg";
import Boden110 from "../Portfolio/Bodenleger/Boden (110).jpg";
import Boden111 from "../Portfolio/Bodenleger/Boden (111).jpg";
import Boden112 from "../Portfolio/Bodenleger/Boden (112).jpg";
import Boden113 from "../Portfolio/Bodenleger/Boden (113).jpg";
import Boden114 from "../Portfolio/Bodenleger/Boden (114).jpg";
import Boden115 from "../Portfolio/Bodenleger/Boden (115).jpg";
import Boden116 from "../Portfolio/Bodenleger/Boden (116).jpg";
import Boden117 from "../Portfolio/Bodenleger/Boden (117).jpg";
import Boden118 from "../Portfolio/Bodenleger/Boden (118).jpg";
import Boden119 from "../Portfolio/Bodenleger/Boden (119).jpg";
import Boden120 from "../Portfolio/Bodenleger/Boden (120).jpg";
import Boden121 from "../Portfolio/Bodenleger/Boden (121).jpg";
import Boden122 from "../Portfolio/Bodenleger/Boden (122).jpg";
import Boden123 from "../Portfolio/Bodenleger/Boden (123).jpg";
import Boden124 from "../Portfolio/Bodenleger/Boden (124).jpg";
import Gipser1 from "../Portfolio/Gipserarbeit/Gipser (1).jpg";
import Gipser2 from "../Portfolio/Gipserarbeit/Gipser (2).jpg";
import Gipser3 from "../Portfolio/Gipserarbeit/Gipser (3).jpg";
import Gipser4 from "../Portfolio/Gipserarbeit/Gipser (4).jpg";
import Gipser5 from "../Portfolio/Gipserarbeit/Gipser (5).jpg";
import Gipser6 from "../Portfolio/Gipserarbeit/Gipser (6).jpg";
import Gipser7 from "../Portfolio/Gipserarbeit/Gipser (7).jpg";
import Gipser8 from "../Portfolio/Gipserarbeit/Gipser (8).jpg";
import Gipser9 from "../Portfolio/Gipserarbeit/Gipser (9).jpg";
import Gipser10 from "../Portfolio/Gipserarbeit/Gipser (10).jpg";
import Gipser11 from "../Portfolio/Gipserarbeit/Gipser (11).jpg";
import Gipser12 from "../Portfolio/Gipserarbeit/Gipser (12).jpg";
import Gipser13 from "../Portfolio/Gipserarbeit/Gipser (13).jpg";
import Gipser14 from "../Portfolio/Gipserarbeit/Gipser (14).jpg";
import Gipser15 from "../Portfolio/Gipserarbeit/Gipser (15).jpg";
import Gipser16 from "../Portfolio/Gipserarbeit/Gipser (16).jpg";
import Lackier1 from "../Portfolio/Lackierarbeit/Lackier (1).jpg";
import Lackier2 from "../Portfolio/Lackierarbeit/Lackier (2).jpg";
import Lackier3 from "../Portfolio/Lackierarbeit/Lackier (3).jpg";
import Lackier4 from "../Portfolio/Lackierarbeit/Lackier (4).jpg";
import Lackier5 from "../Portfolio/Lackierarbeit/Lackier (5).jpg";
import Lackier6 from "../Portfolio/Lackierarbeit/Lackier (6).jpg";
import Lackier7 from "../Portfolio/Lackierarbeit/Lackier (7).jpg";
import Lackier8 from "../Portfolio/Lackierarbeit/Lackier (8).jpg";
import Lackier9 from "../Portfolio/Lackierarbeit/Lackier (9).jpg";
import Lackier10 from "../Portfolio/Lackierarbeit/Lackier (10).jpg";
import Lackier11 from "../Portfolio/Lackierarbeit/Lackier (11).jpg";
import Lackier12 from "../Portfolio/Lackierarbeit/Lackier (12).jpg";
import Lackier13 from "../Portfolio/Lackierarbeit/Lackier (13).jpg";
import Lackier14 from "../Portfolio/Lackierarbeit/Lackier (14).jpg";
import Lackier15 from "../Portfolio/Lackierarbeit/Lackier (15).jpg";
import Lackier16 from "../Portfolio/Lackierarbeit/Lackier (16).jpg";
import Lackier17 from "../Portfolio/Lackierarbeit/Lackier (17).jpg";
import Lackier18 from "../Portfolio/Lackierarbeit/Lackier (18).jpg";
import Lackier19 from "../Portfolio/Lackierarbeit/Lackier (19).jpg";
import Lackier20 from "../Portfolio/Lackierarbeit/Lackier (20).jpg";
import Lackier21 from "../Portfolio/Lackierarbeit/Lackier (21).jpg";
import Lackier22 from "../Portfolio/Lackierarbeit/Lackier (22).jpg";
import Lackier23 from "../Portfolio/Lackierarbeit/Lackier (23).jpg";
import Lackier24 from "../Portfolio/Lackierarbeit/Lackier (24).jpg";
import Lackier25 from "../Portfolio/Lackierarbeit/Lackier (25).jpg";
import Lackier26 from "../Portfolio/Lackierarbeit/Lackier (26).jpg";
import Lackier27 from "../Portfolio/Lackierarbeit/Lackier (27).jpg";
import Lackier28 from "../Portfolio/Lackierarbeit/Lackier (28).jpg";
import Lackier29 from "../Portfolio/Lackierarbeit/Lackier (29).jpg";
import Lackier30 from "../Portfolio/Lackierarbeit/Lackier (30).jpg";
import Lackier31 from "../Portfolio/Lackierarbeit/Lackier (31).jpg";
import Lackier32 from "../Portfolio/Lackierarbeit/Lackier (32).jpg";
import Lackier33 from "../Portfolio/Lackierarbeit/Lackier (33).jpg";
import Lackier34 from "../Portfolio/Lackierarbeit/Lackier (34).jpg";
import Lackier35 from "../Portfolio/Lackierarbeit/Lackier (35).jpg";
import Lackier36 from "../Portfolio/Lackierarbeit/Lackier (36).jpg";
import Lackier37 from "../Portfolio/Lackierarbeit/Lackier (37).jpg";
import Lackier38 from "../Portfolio/Lackierarbeit/Lackier (38).jpg";
import Lackier39 from "../Portfolio/Lackierarbeit/Lackier (39).jpg";
import Lackier40 from "../Portfolio/Lackierarbeit/Lackier (40).jpg";
import Lackier41 from "../Portfolio/Lackierarbeit/Lackier (41).jpg";
import Lackier42 from "../Portfolio/Lackierarbeit/Lackier (42).jpg";
import Lackier43 from "../Portfolio/Lackierarbeit/Lackier (43).jpg";
import Maler1 from "../Portfolio/Maler/Maler (1).jpg";
import Maler2 from "../Portfolio/Maler/Maler (2).jpg";
import Maler3 from "../Portfolio/Maler/Maler (3).jpg";
import Maler4 from "../Portfolio/Maler/Maler (4).jpg";
import Maler5 from "../Portfolio/Maler/Maler (5).jpg";
import Maler6 from "../Portfolio/Maler/Maler (6).jpg";
import Maler7 from "../Portfolio/Maler/Maler (7).jpg";
import Maler8 from "../Portfolio/Maler/Maler (8).jpg";
import Maler9 from "../Portfolio/Maler/Maler (9).jpg";
import Maler10 from "../Portfolio/Maler/Maler (10).jpg";
import Maler11 from "../Portfolio/Maler/Maler (11).jpg";
import Maler12 from "../Portfolio/Maler/Maler (12).jpg";
import Maler13 from "../Portfolio/Maler/Maler (13).jpg";
import Maler14 from "../Portfolio/Maler/Maler (14).jpg";
import Maler15 from "../Portfolio/Maler/Maler (15).jpg";
import Maler16 from "../Portfolio/Maler/Maler (16).jpg";
import Maler17 from "../Portfolio/Maler/Maler (17).jpg";
import Maler18 from "../Portfolio/Maler/Maler (18).jpg";
import Maler19 from "../Portfolio/Maler/Maler (19).jpg";
import Maler20 from "../Portfolio/Maler/Maler (20).jpg";
import Maler21 from "../Portfolio/Maler/Maler (21).jpg";
import Maler22 from "../Portfolio/Maler/Maler (22).jpg";
import Maler23 from "../Portfolio/Maler/Maler (23).jpg";
import Maler24 from "../Portfolio/Maler/Maler (24).jpg";
import Maler25 from "../Portfolio/Maler/Maler (25).jpg";
import Maler26 from "../Portfolio/Maler/Maler (26).jpg";
import Maler27 from "../Portfolio/Maler/Maler (27).jpg";
import Maler28 from "../Portfolio/Maler/Maler (28).jpg";
import Maler29 from "../Portfolio/Maler/Maler (29).jpg";
import Maler30 from "../Portfolio/Maler/Maler (30).jpg";
import Maler31 from "../Portfolio/Maler/Maler (31).jpg";
import Maler32 from "../Portfolio/Maler/Maler (32).jpg";
import Maler33 from "../Portfolio/Maler/Maler (33).jpg";
import Maler34 from "../Portfolio/Maler/Maler (34).jpg";
import Maler35 from "../Portfolio/Maler/Maler (35).jpg";
import Maler36 from "../Portfolio/Maler/Maler (36).jpg";
import Maler37 from "../Portfolio/Maler/Maler (37).jpg";
import Maler38 from "../Portfolio/Maler/Maler (38).jpg";
import Maler39 from "../Portfolio/Maler/Maler (39).jpg";
import Maler40 from "../Portfolio/Maler/Maler (40).jpg";
import Maler41 from "../Portfolio/Maler/Maler (41).jpg";
import Maler42 from "../Portfolio/Maler/Maler (42).jpg";
import Maler43 from "../Portfolio/Maler/Maler (43).jpg";
import Maler44 from "../Portfolio/Maler/Maler (44).jpg";
import Maler45 from "../Portfolio/Maler/Maler (45).jpg";
import Maler46 from "../Portfolio/Maler/Maler (46).jpg";
import Maler47 from "../Portfolio/Maler/Maler (47).jpg";
import Maler48 from "../Portfolio/Maler/Maler (48).jpg";
import Maler49 from "../Portfolio/Maler/Maler (49).jpg";
import Maler50 from "../Portfolio/Maler/Maler (50).jpg";
import Maler51 from "../Portfolio/Maler/Maler (51).jpg";
import Maler52 from "../Portfolio/Maler/Maler (52).jpg";
import Maler53 from "../Portfolio/Maler/Maler (53).jpg";
import Maler54 from "../Portfolio/Maler/Maler (54).jpg";
import Maler55 from "../Portfolio/Maler/Maler (55).jpg";
import Maler56 from "../Portfolio/Maler/Maler (56).jpg";
import Maler57 from "../Portfolio/Maler/Maler (57).jpg";
import Maler58 from "../Portfolio/Maler/Maler (58).jpg";
import Maler59 from "../Portfolio/Maler/Maler (59).jpg";
import Maler60 from "../Portfolio/Maler/Maler (60).jpg";
import Maler61 from "../Portfolio/Maler/Maler (61).jpg";
import Maler62 from "../Portfolio/Maler/Maler (62).jpg";
import Maler63 from "../Portfolio/Maler/Maler (63).jpg";
import Maler64 from "../Portfolio/Maler/Maler (64).jpg";
import Maler65 from "../Portfolio/Maler/Maler (65).jpg";
import Maler66 from "../Portfolio/Maler/Maler (66).jpg";
import Maler67 from "../Portfolio/Maler/Maler (67).jpg";
import Maler68 from "../Portfolio/Maler/Maler (68).jpg";
import Trocken1 from "../Portfolio/Trockenbau/Trocken (1).jpg";
import Trocken2 from "../Portfolio/Trockenbau/Trocken (2).jpg";
import Trocken3 from "../Portfolio/Trockenbau/Trocken (3).jpg";
import Trocken4 from "../Portfolio/Trockenbau/Trocken (4).jpg";
import Trocken5 from "../Portfolio/Trockenbau/Trocken (5).jpg";
import Trocken6 from "../Portfolio/Trockenbau/Trocken (6).jpg";
import Trocken7 from "../Portfolio/Trockenbau/Trocken (7).jpg";
import Trocken8 from "../Portfolio/Trockenbau/Trocken (8).jpg";
import Trocken9 from "../Portfolio/Trockenbau/Trocken (9).jpg";
import Trocken10 from "../Portfolio/Trockenbau/Trocken (10).jpg";
import Trocken11 from "../Portfolio/Trockenbau/Trocken (11).jpg";
import Trocken12 from "../Portfolio/Trockenbau/Trocken (12).jpg";
import Trocken13 from "../Portfolio/Trockenbau/Trocken (13).jpg";
import Trocken14 from "../Portfolio/Trockenbau/Trocken (14).jpg";
import Trocken15 from "../Portfolio/Trockenbau/Trocken (15).jpg";
import Trocken16 from "../Portfolio/Trockenbau/Trocken (16).jpg";
import Trocken17 from "../Portfolio/Trockenbau/Trocken (17).jpg";
import Trocken18 from "../Portfolio/Trockenbau/Trocken (18).jpg";
import Trocken19 from "../Portfolio/Trockenbau/Trocken (19).jpg";

const Fassadenarbeiten = [
  {
    src: Fassaden1,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden2,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden3,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden4,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden5,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden6,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden7,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden8,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden9,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden10,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden11,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden12,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden13,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden14,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden15,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden16,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden17,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden18,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden19,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden20,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden21,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden22,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden23,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden24,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden25,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden26,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden27,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden28,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden29,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden30,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden31,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden32,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden33,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden34,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden35,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden36,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden37,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden38,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden39,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden40,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden41,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden42,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden43,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden44,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden45,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden46,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden47,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden48,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden49,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden50,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden51,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden52,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden53,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden54,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden55,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden56,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden57,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden58,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden59,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden60,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden61,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden62,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden63,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden64,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden65,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden66,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden67,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden68,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden69,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden70,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden71,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden72,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden73,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden74,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden75,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden76,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden77,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden78,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden79,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden80,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden81,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden82,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden83,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden84,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden85,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden86,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden87,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden88,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden89,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden90,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden91,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden92,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden93,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden94,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden95,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden96,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden97,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden98,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden99,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden100,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden101,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden102,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden103,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden104,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden105,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden106,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden107,
    width: 1024,
    height: 1024,
  },
  {
    src: Fassaden108,
    width: 1024,
    height: 1024,
  },
];

const Bodenverlegung = [
  {
    src: Boden1,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden2,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden3,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden4,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden5,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden6,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden7,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden8,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden9,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden10,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden11,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden12,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden13,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden14,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden15,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden16,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden17,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden18,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden19,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden20,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden21,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden22,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden23,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden24,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden25,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden26,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden27,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden28,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden29,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden30,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden31,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden32,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden33,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden34,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden35,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden36,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden37,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden38,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden39,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden40,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden41,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden42,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden43,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden44,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden45,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden46,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden47,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden48,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden49,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden50,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden51,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden52,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden53,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden54,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden55,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden56,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden57,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden58,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden59,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden60,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden61,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden62,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden63,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden64,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden65,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden66,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden67,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden68,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden69,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden70,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden71,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden72,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden73,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden74,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden75,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden76,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden77,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden78,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden79,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden80,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden81,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden82,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden83,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden84,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden85,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden86,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden87,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden88,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden89,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden90,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden91,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden92,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden93,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden94,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden95,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden96,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden97,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden98,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden99,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden100,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden101,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden102,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden103,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden104,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden105,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden106,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden107,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden108,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden109,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden110,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden111,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden112,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden113,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden114,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden115,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden116,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden117,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden118,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden119,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden120,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden121,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden122,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden123,
    width: 1024,
    height: 1024,
  },
  {
    src: Boden124,
    width: 1024,
    height: 1024,
  },
];

const Gipserarbeiten = [
  {
    src: Gipser1,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser2,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser3,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser4,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser5,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser6,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser7,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser8,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser9,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser10,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser11,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser12,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser13,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser14,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser15,
    width: 1024,
    height: 1024,
  },
  {
    src: Gipser16,
    width: 1024,
    height: 1024,
  },
];

const Lackierarbeiten = [
  {
    src: Lackier1,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier2,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier3,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier4,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier5,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier6,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier7,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier8,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier9,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier10,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier11,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier12,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier13,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier14,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier15,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier16,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier17,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier18,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier19,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier20,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier21,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier22,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier23,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier24,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier25,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier26,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier27,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier28,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier29,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier30,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier31,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier32,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier33,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier34,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier35,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier36,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier37,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier38,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier39,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier40,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier41,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier42,
    width: 1024,
    height: 1024,
  },
  {
    src: Lackier43,
    width: 1024,
    height: 1024,
  },
];

const Malerarbeiten = [
  {
    src: Maler1,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler2,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler3,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler4,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler5,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler6,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler7,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler8,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler9,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler10,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler11,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler12,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler13,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler14,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler15,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler16,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler17,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler18,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler19,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler20,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler21,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler22,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler23,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler24,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler25,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler26,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler27,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler28,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler29,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler30,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler31,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler32,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler33,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler34,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler35,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler36,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler37,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler38,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler39,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler40,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler41,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler42,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler43,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler44,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler45,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler46,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler47,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler48,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler49,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler50,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler51,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler52,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler53,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler54,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler55,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler56,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler57,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler58,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler59,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler60,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler61,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler62,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler63,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler64,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler65,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler66,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler67,
    width: 1024,
    height: 1024,
  },
  {
    src: Maler68,
    width: 1024,
    height: 1024,
  },
];

const Trockenbau = [
  {
    src: Trocken1,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken2,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken3,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken4,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken5,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken6,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken7,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken8,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken9,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken10,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken11,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken12,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken13,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken14,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken15,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken16,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken17,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken18,
    width: 1024,
    height: 1024,
  },
  {
    src: Trocken19,
    width: 1024,
    height: 1024,
  },
];

export {
  Bodenverlegung,
  Fassadenarbeiten,
  Trockenbau,
  Malerarbeiten,
  Gipserarbeiten,
  Lackierarbeiten,
};
