import React from "react";
import About from "../components/About";
import Hero from "../components/Hero";
import Services from "../components/Services";

function HomePage() {
  return (
    <div>
      <Hero />
      <About />
      <Services />
    </div>
  );
}

export default HomePage;
